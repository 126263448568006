import { useState } from "react";
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import TextField from "./text-field";

export const PhoneField = ({
  value,
  id,
  inputClassName,
  labelClassName,
  onFocusEffect,
  onClick,
  onChange,
  onMouseOver,
  placeholder,
  label,
  className,
  defaultValue
}) => {
  const [phone, setPhone] = useState("");
  return (
    <TextField
      className={className}
      value={value}
      defaultValue={defaultValue}
      id={id}
      inputClassName={inputClassName}
      label={label}
      placeholder={placeholder ? placeholder : "Insert phone number"}
      type={"tel"}
      labelClassName={labelClassName}
      onFocusEffect={onFocusEffect}
      onFocusEffectColor={isValidPhoneNumber(phone) ? "petroil" : "red"}
      onClick={onClick}
      onChange={(e) => {
        setPhone(formatPhoneNumberIntl(e.target.value));
        if (onChange) {
          onChange(e);
        }
      }}
      onMouseOver={onMouseOver}
      maxLength={13}
    />
  );
};
