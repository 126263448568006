import TextField from "./text-field";

export const PasswordField = ({
  id,
  inputClassName,
  labelClassName,
  onFocusEffect,
  onClick,
  onChange,
  onMouseOver,
  label,
  value,
  className,
  type
}) => {
  return (
    <TextField
      className={className}
      value={value}
      id={id}
      inputClassName={inputClassName}
      label={label}
      placeholder={"Insert password"}
      type={type}
      labelClassName={labelClassName}
      onFocusEffect={onFocusEffect}
      onClick={onClick}
      onChange={onChange}
      onMouseOver={onMouseOver}
      autoComplete={"new-password"}
    />
  );
};
