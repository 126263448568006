import React from "react";
import TextField from "./text-field";

export const UnitField = ({
  value,
  onChange,
  inputClassName,
  label,
  className,
  labelClassName,
  placeholder,
  defaultValue
}) => {
  return (
    <TextField
      id="displayNameField"
      inputClassName={inputClassName}
      label={label}
      className={className}
      defaultValue={defaultValue}
      labelClassName={labelClassName}
      placeholder={placeholder}
      type="number"
      value={value}
      min={0}
      onChange={onChange}
    />
  );
};
