import React from "react";
import Select from "react-select";

export const SelectMenu = ({
  label,
  labelClassName,
  className,
  options,
  isMulti,
  placeholder,
  isSearchable,
  isLoading,
  isClearable,
  isDisabled,
  onChange,
  value,
  defaultValue,
}) => {
  return (
    <div
      className={
        "flex flex-col items-start justify-start w-full flex-nowrap m-1 " +
        className
      }
    >
      {label && (
        <label
          className={
            labelClassName + " " + "text-lg font-semibold text-blue w-full"
          }
        >
          {label}
        </label>
      )}
      <Select
        isLoading={isLoading}
        isClearable={isClearable}
        isDisabled={isDisabled}
        options={options ? options : undefined}
        isMulti={isMulti}
        isSearchable={isSearchable}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        className={"w-full my-1 text-base font-normal"}
        theme={(theme) => ({
          ...theme,
          borderRadius: "0.375rem",
          colors: {
            primary: "#0a6d67",
            danger: "#dc2626",
            neutral0: "rgb(245, 245, 245)",
          },
        })}
      />
    </div>
  );
};
