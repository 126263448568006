import React from "react";

export const NeutralButton = ({ id, onClick, className, content }) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={
        "w-full min-w-fit h-fit bg-white border text-blue rounded-lg text-base font-semibold hover:brightness-95 " +
        " " +
        className
      }
    >
      {content}
    </button>
  );
};
