import React, { useEffect, useState } from 'react'
import {BiErrorCircle} from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

export default function NotFound() {
  const [seconds, setSeconds] = useState(5)
  const navigate = useNavigate()
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    } else {
      navigate('/')
    }
  }) 
  return (
    <div className="flex items-center justify-center h-[80vh] text-blue">
      <div className="flex flex-col items-center justify-center p-10 border-2 shadow-2xl rounded-xl">
        <div className="flex items-center">
          <BiErrorCircle className="h-auto mr-6 text-petroil w-36" />
          <h1 className="text-7xl">Page Not Found (404)</h1>
        </div>
        <p className="text-lg">Going to home page in: {seconds} seconds</p>
      </div>
    </div>
  );
}