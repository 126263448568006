import React from "react";

const SecondaryButton = ({ id, className, onClick, content }) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={
        className +
        " " +
        "w-full min-w-fit h-fit bg-petroil text-white rounded-lg text-base font-semibold hover:brightness-125"
      }
    >
      {content}
    </button>
  );
};

export default SecondaryButton;
