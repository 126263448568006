import React from "react";

const TextField = ({
  id,
  label,
  labelClassName,
  inputClassName,
  placeholder,
  onChange,
  value,
  onFocusEffect,
  onFocusEffectColor,
  onClick,
  onMouseOver,
  type,
  maxLength,
  autoComplete,
  name,
  spellCheck,
  className,
  notEditableValue,
  min,
  defaultValue,
  accept
}) => {
  return (
    <div
      className={
        className + " " + "flex flex-col items-start justify-start w-full"
      }
    >
      {label && (
        <label
          className={
            labelClassName + " " + "ml-1 text-lg font-semibold text-blue w-full"
          }
        >
          {label}
        </label>
      )}
      <input
        id={id}
        className={
          inputClassName +
          " " +
          "w-full h-fit my-1 bg-gray-100 border-2 rounded-md outline-none indent-2 text-base text-blue" +
          " " +
          (onFocusEffect
            ? onFocusEffectColor
              ? "focus:border-" + onFocusEffectColor
              : "focus:border-petroil"
            : "")
        }
        placeholder={placeholder}
        defaultValue={defaultValue}
        type={type ? type : "text"}
        onChange={onChange}
        onClick={onClick}
        onMouseOver={onMouseOver}
        maxLength={maxLength ? maxLength : null}
        autoComplete={autoComplete}
        value={value}
        name={name}
        spellCheck={spellCheck}
        min={min}
        accept={accept}
      />
    </div>
  );
};

export default TextField;
