import React from "react";

const TertiaryButton = ({ id, className, onClick, content }) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={
        " h-fit min-w-fit text-white rounded-lg text-base font-semibold hover:brightness-125" +
        " " +
        className
      }
    >
      {content}
    </button>
  );
};

export default TertiaryButton;
