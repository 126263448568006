import { useState } from "react";
import { MdClose, MdOutlineSegment } from "react-icons/md";
import { SideBar } from "./sidebar";
import { Transition } from "@headlessui/react";

export const BurgerSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="fixed w-screen big-screen:hidden">
      {isOpen && (
        <SideBar
          className={"w-full"}
          setIsOpen={(value) => {
            setIsOpen(value);
          }}
        />
      )}
      <div className={(isOpen ? "right-0" : " left-0 ") + " fixed bottom-5"}>
        <div
          className="w-16 h-16 p-3 mx-5 rounded-full cursor-pointer bg-petroil"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <MdClose className="w-full h-auto text-white" />
          ) : (
            <MdOutlineSegment className="w-full h-auto text-white" />
          )}
        </div>
      </div>
    </div>
  );
};
