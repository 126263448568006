import { useState } from "react";
import TextField from "./text-field";

export const EmailField = ({
  id,
  inputClassName,
  labelClassName,
  onFocusEffect,
  onClick,
  onChange,
  onMouseOver,
  placeholder,
  label,
  value,
  className,
}) => {
  const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/;
  const [email, setEmail] = useState("");
  return (
    <TextField
      className={className}
      id={id}
      value={value}
      inputClassName={inputClassName}
      label={label}
      placeholder={placeholder ? placeholder : "Insert email"}
      type={"email"}
      labelClassName={labelClassName}
      onFocusEffect={onFocusEffect}
      onFocusEffectColor={regEx.test(email) ? "petroil" : "red"}
      onClick={onClick}
      spellCheck={false}
      onChange={(e) => {
        setEmail(e.target.value);
        if (onChange) onChange(e);
      }}
      autoComplete={"email"}
      onMouseOver={onMouseOver}
    />
  );
};
