import React from "react";

const PrimaryButton = ({ id, className, onClick, content }) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={
        "w-full min-w-fit h-fit bg-green  text-blue rounded-lg text-base font-semibold hover:brightness-95 " +
        " " +
        className
      }
    >
        {content}
    </button>
  );
};

export default PrimaryButton;
